<template>
	<div class="booking-edit">
		<div class="booking-edit__wrapper">
			<div class="booking-edit__title-wrapper booking-edit-title">
				<h2 class="booking-edit-title__content">Внести изменения</h2>

				<tk-link
					href="#"
					@click.prevent="$router.back()"
					kind="common-green"
					class="booking-edit-title__cancel-button"
				>
					Отменить
				</tk-link>
			</div>

			<div class="booking-edit__changing-data dates-n-guests">
				<div class="dates-n-guests__dates edit-dates">
					<h3 class="title">Даты прибытия и выезда</h3>
					<tk-datepicker
						class="edit-dates__arrive-dates"
						v-model="arriveDatesValue"
						type="daterange"
						:clearable="false"
						range-separator="-"
						format="DD.MM"
						@change="changeDate"
					/>
				</div>

				<tk-drop-down>
					<template v-slot:toggle>
						<div class="dates-n-guests__guests booking-edit-guests">
							<h3 class="title">Количество гостей</h3>
							<p class="booking-edit-guests__count">{{ guestsTotal }} гостей</p>
						</div>
					</template>
					<template v-slot:content>
						<div class="booking-edit-guests__guests-quantity guests-quantity">
							<ul class="guests-quantity__list">
								<li class="guests-quantity__item">
									<p class="text">Взрослые</p>
                  <tk-input-number
                    v-model="content.guests"
                    :min="1"
                    :max="10"
                    @change="(event) => (form.guests = event)"
                  />
								</li>
								<li class="guests-quantity__item">
									<p class="text">Дети до 14 лет</p>
                  <tk-input-number
                    v-model="content.children"
                    :min="0"
                    :max="10"
                    @change="(event) => (form.children = event)"
                  />
								</li>
							</ul>
						</div>
					</template>
				</tk-drop-down>
			</div>

			<ul v-if="Object.keys(calculates).length" class="booking-edit__invoice booking-edit-invoice">
				<!-- <li class="booking-edit-invoice__item booking-invoice">
					<p class="booking-invoice__service">
						+{{ services.dayQantity }} ночи
					</p>
					<p class="booking-invoice__cost">{{ daysTotal }} ₽</p>
				</li> -->

				<li
					v-if="calculates.commission"
					class="booking-edit-invoice__item booking-invoice"
				>
					<div class="booking-invoice__wrapper">
						<p class="booking-invoice__service">
							Комиссия {{ calculates.commission.percentage }}%
						</p>
						<p class="booking-invoice__description">За пользование сервисом</p>
					</div>
					<p class="booking-invoice__cost">
						{{ calculates.commission.total }} ₽
					</p>
				</li>

				<li
					v-if="calculates.result"
					class="booking-edit-invoice__item booking-invoice"
				>
					<div class="booking-invoice__wrapper">
						<p class="booking-invoice__service">Итого:</p>
						<p class="booking-invoice__description">
							С учетом скидки за статус
						</p>
					</div>
					<div class="booking-invoice__cost">
						<p class="amount">{{ calculates.result.amount }} ₽</p>
						<p class="total-amount">{{ calculates.result.total }}₽</p>
					</div>
				</li>
			</ul>

			<p class="booking-edit__warning">
				Это конечная сумма. <br />Дополнительные сборы взиматься не будут.
			</p>

			<tk-button class="booking-edit__submit" @click="submit">
				Сохранить и оплатить
			</tk-button>
		</div>

		<div class="booking-edit__rules rules-booking-edit">
			<h2 class="section-title rules-booking-edit__title">
				Правила изменения бронирования
			</h2>
			<div class="rules-booking-edit__text">
				<p>
					При отправке запросов на изменение необходимо учитывать следующее:
				</p>
				<p>
					- Вы можете отправить или получить запрос на изменение до въезда.
					Ответ в ближайшее время — лучший способ избежать отмены бронирования
					или недопонимания с хозяиномили гостем.
				</p>
				<p>- Никаких штрафов за отклонение запроса не предусмотрено.</p>
				<p>- Если даты бронирования уже прошли, его нельзя изменить.</p>
				<p>
					- Если хозяин изменил расценкина выбранные даты до того,как вы
					запросили изменения,то стоимость нового бронирования сформируется с
					учетом новых расценок.
				</p>
				<p>
					Если для бронирования действует скидка, после изменения дат она может
					стать недействительной.
				</p>
			</div>
		</div>
	</div>
</template>

<script>
import { getDateInFormat } from '@/utils/dateTime'

export default {
	watch: {
		form: {
			handler() {
				this.calculate()
			},
			deep: true
		}
	},
	data() {
		return {
			content: {},
      calculates: {},
			form: {
				children: 0,
				guests: 0,
				date_from: '',
				date_to: ''
			},
			arriveDatesValue: [],
			calcCount: 1
		}
	},
	computed: {
		guestsTotal() {
			return this.content.children + this.content.guests
		}
	},
	methods: {
		bookingEditComplete() {
			this.$store.commit('showModal', {
				name: modals.BOOKING_EDIT_COMPLETE,
				props: null,
				isShow: true
			})
		},
		async calculate() {
			if (this.calcCount === 1) {
				this.calcCount += 1

				return
			}

			const response = await this.$api.place.calculate({
				place_id: this.content.place_id,
				...this.form
			})

			if (response && response.status) {
        this.calculates = { ...response.response }
			}
		},
		getDate(payload) {
      const date = new Date(payload)
      return (
        date.getFullYear() +
        '-' +
        ('0' + (date.getMonth() + 1)).slice(-2) +
        '-' +
        ('0' + date.getDate()).slice(-2)
      )
		},
		changeDate(event) {
			this.form.date_from = this.getDate(event[0])
			this.form.date_to = this.getDate(event[1])
		},
		async submit() {
			const response = await this.$api.reserve.update({
				reserve_id: this.content.id,
				...this.form
			})

			if (response && response.status) {
				this.bookingEditComplete()
			} else {
				const err =
					response.errors?.date_start ||
					response.errors?.date_end ||
					response.errors?.guests ||
          response.errors?.reserve ||
          []
				this.$store.commit('showNotification', {
					type: 'error',
					text:
            err[0] ||
						'Ошибка изменения бронирования. Обратитесь к администратору'
				})
			}
		}
	},
	async beforeMount() {
		const result = await this.$api.reserve.getReserve({ reserve_id: this.$route.params.id })

		if (result && result.status) {
			this.content = { ...result.response }

			this.arriveDatesValue = [this.content.date_from, this.content.date_to]

			this.form = {
				children: this.content.children,
				guests: this.content.guests,
				date_from: this.content.date_from,
				date_to: this.content.date_to
			}
		}
	},
}
</script>

<style lang="scss">
.back-button {
	visibility: hidden;
}
</style>

<style lang="sass">
.booking-edit
  display: flex

  &__title-wrapper
    margin-bottom: 30px

  .booking-edit-title
    display: flex
    align-items: center
    justify-content: space-between

    &__content
      margin: 0

      font-style: normal
      font-weight: bold
      font-size: 22px
      color: black
      line-height: 26px

  &__wrapper
    margin-right: 130px
    width: 100%

  .dates-n-guests
    position: relative
    display: flex
    margin-bottom: 48px

    .title
      font-weight: 600
      font-size: 16px
      line-height: 18px
      margin: 0
      margin-bottom: 15px

    &__dates
      width: 187px
      margin-right: 33px

    .edit-dates
      height: 18px

      &__arrive-dates
        width: 110px
        height: 20px
        padding: 0

        border: none
        border-radius: unset
        background: transparent

        .el-range__close-icon,
        .el-range__icon
          display: none

        .el-range-input
          width: 50px
          padding-left: 0
          font-size: 16px
          line-height: 16px
          color: #1AC386
          font-size: 18px
          line-height: 18px
          text-decoration: underline
          cursor: pointer
          background: transparent

        .el-range-separator
          width: 15%
          line-height: 20px

    &__guests
      width: 141px

    .booking-edit-guests
      &__count
        margin: 0

        font-size: 18px
        line-height: 18px
        text-decoration-line: underline
        color: #1AC386
        cursor: pointer

    &__guests-quantity
      position: absolute
      right: -330px
      width: 310px

    .guests-quantity
      width: 310px

      &__list
        width: 100%
        padding-left: 0

        background-color: #ffffff
        box-shadow: 0px 10px 36px rgba(95, 83, 80, 0.1), 0px 10px 36px rgba(0, 0, 0, 0.1)
        border-radius: 12px

      &__item
        display: flex
        justify-content: space-between
        align-items: center
        padding: 20px

        &:not(:last-child)
          border-bottom: 1px solid #CBCBCB

        .text
          margin: 0

          font-size: 16px
          line-height: 21px

        .el-input-number
          max-width: 95px

          .el-input__inner
            font-size: 16px
            line-height: 22px

  .booking-edit-invoice
    padding-top: 20px
    padding-left: 0
    margin: 0
    margin-bottom: 16px

    list-style: none

    background: #FFFFFF
    border-radius: 13px
    box-shadow: 0px 8px 24px rgba(126, 161, 149, 0.13)

    &__item
      display: flex
      justify-content: space-between
      padding-bottom: 15px

      &:not(:last-child)
        margin-left: 20px
        margin-right: 20px

      &:not(:first-child)
        padding-top: 15px

      &:not(:first-child):not(:last-child)
        border-top: 1px solid #F0F0F0

      &:last-child
        padding: 20px 20px

        border-top: 13px dotted #f5f5f5

    .booking-invoice
      &__service,
      &__cost
        display: flex
        
        font-weight: bold
        font-size: 18px
        line-height: 21px
        letter-spacing: 0.005em

        .amount
          margin-right: 12px
        
          opacity: 0.3

        .total-amount
          color: #FF0000

      &__description
        margin-top: 12px

        font-size: 12px
        line-height: 14px
        opacity: 0.5

  &__warning
    margin-bottom: 30px

    font-size: 14px
    line-height: 16px
    color: #CBCBCB

  &__submit
    width: 210px
    height: 55px

  .rules-booking-edit
    &__title
      margin-bottom: 30px
    &__text
      font-size: 16px
      line-height: 18px

@media screen and (max-width: 1440px)
  .booking-edit__wrapper
    margin-right: 60px

@media screen and (max-width: 1180px)
  .booking-edit__wrapper
    margin-right: 40px

@media screen and (max-width: 1024px)
  .booking-edit
    flex-wrap: wrap
    &__wrapper
      margin-right: 0

      .dates-n-guests
        display: block
        margin-bottom: 30px
        .dates
          margin-bottom: 30px
        .guests
          margin-bottom: 30px
        .guests-quantity
          position: static

    &__rules
      margin-top: 0px

@media screen and (max-width: 912px)
  .booking-edit
    .dates-n-guests
      .dates
        margin-bottom: 30px

@media screen and (max-width: 800px)
  .booking-edit
    .dates-n-guests
      .guests-quantity
        width: auto
    .invoice
      &-item
        &__cost
          display: block
</style>